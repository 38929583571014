<template>
  <div class="rounding-surveillance">
    <LoadingSpinner :isLoading="isLoading" />
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 0 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(0)">{{ $t("secure_tool.log") }}</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 3 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(3)">{{ $t("secure_tool.report") }}</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 2 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(2)">{{ $t("secure_tool.secure_pos") }}</div>
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="currentPageTab == 1 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeCurrentPage(1)">{{ $t("secure_tool.map") }}</div>
        </div>
        <div class="float-right flex flex-wrap">
          <div class="datepicker-wrapper mx-2 mt-2 lg:mt-0">
            <date-picker v-model="date_range" type="date" :lang="date_picker_lang" :key="date_picker_langKey" range :editable="false" :clearable="false" @change="loadCurrentTabData()" :disabled-date="disableDate">
              <template v-slot:footer="{ emit }">
                <div class="text-left hidden lg:block">
                  <button class="datepicker-action-btn mr-1" @click="selectToday(emit)">{{ $t("secure_tool.today") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentWeek(emit)">{{ $t("secure_tool.this_week") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastWeek(emit)">{{ $t("secure_tool.last_week") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentMonth(emit)">{{ $t("secure_tool.this_month") }}</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastMonth(emit)">{{ $t("secure_tool.last_month") }}</button>
                </div>
              </template>
            </date-picker>
          </div>
          <div class="select-wrapper mx-2 mt-2 lg:mt-0" v-if="type == 'customer' && units">
            <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-1 py-1" v-model="selected_unit" @change="selectChangeFilter()">
              <option value="">{{ $t("secure_tool.all_activities") }}</option>
              <option v-for="u in units" :key="u._id" :value="u.unit_id">{{ u.name }}</option>
            </select>
          </div>
          <div class="select-wrapper mx-2 mt-2 lg:mt-0" v-if="alarms.length > 0">
            <select class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 disabled:opacity-50 px-1 py-1" v-model="selected_alarm" @change="selectChangeFilterAlarm()" :disabled="selected_unit == '' && type == 'customer'" :readonly="selected_unit == '' && type == 'customer'">
              <option value="">{{ $t("secure_tool.all_alarms") }}</option>
              <option v-for="a in dropdown_alarms" :key="a._id" :value="a.imei_number">{{ a.unit_name }}</option>
            </select>
          </div>
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-secondary-outline mx-1 mt-2 lg:mt-0" @click="resetFilters()">
              {{ $t("secure_tool.reset") }}
              <BaseIcon icon="undo" class="ml-1" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="currentPageTab !== 3" class="w-full bg-gray-50 px-2 py-2 clearfix mt-2">
        <div v-if="currentPageTab == 0" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(1)">{{ $t("secure_tool.all") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(2)">{{ $t("secure_tool.live_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(3)">{{ $t("secure_tool.internal_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(4)">{{ $t("secure_tool.man_down") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 5 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(5)">{{ $t("secure_tool.low_battery") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 6 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(6)">{{ $t("secure_tool.radio_beacon") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 7 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(7)">{{ $t("secure_tool.test_alarm_performed") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 8 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(8)">{{ $t("secure_tool.timer_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 9 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(9)">{{ $t("secure_tool.on_off") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 10 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(10)">{{ $t("secure_tool.loading") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 11 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(11)">{{ $t("secure_tool.gsm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="table_tab == 12 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeTableFilter(12)">{{ $t("secure_tool.geofence") }}</button>
        </div>
        <div v-if="currentPageTab == 1" class="float-left flex flex-wrap">
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 1 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(1)">{{ $t("secure_tool.all") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 2 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(2)">{{ $t("secure_tool.live_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 8 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(8)">{{ $t("secure_tool.internal_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 9 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(9)">{{ $t("secure_tool.man_down") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 3 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(3)">{{ $t("secure_tool.daily_tracking") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 4 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(4)">{{ $t("secure_tool.live_alarm_tracking") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 5 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(5)">{{ $t("secure_tool.follow_alarm") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 6 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(6)">{{ $t("secure_tool.current_location") }}</button>
          <button class="btn-secondary-outline text-xxs mx-1 mt-2 lg:mt-0" v-bind:class="map_filter == 7 ? 'bg-secondary-600 text-white border-secondary-600' : 'hover:bg-secondary-400 hover:border-secondary-400 hover:text-white'" @click="changeMapFilter(7)">{{ $t("secure_tool.geofence_zone") }}</button>
        </div>
        <div class="float-right flex flex-wrap">
          <button class="btn-blue mx-1 mt-2 lg:mt-0" @click="show('email-notification')">
            {{ $t("secure_tool.notice") }}
            <BaseIcon icon="envelope" class="ml-2" />
          </button>
        </div>
      </div>
      <div class="w-full mt-6">
        <div v-show="currentPageTab == 0">
          <vue-good-table
            ref="securtoolLogTable"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="columns"
            :rows="rows_filtered"
            :search-options="{
              enabled: true,
              placeholder: $t('search'),
            }"
            :pagination-options="{
              enabled: true,
              perPage: 100,
              perPageDropdown: [100, 200, 500],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex flex-wrap">
                <button class="btn-blue-outline mx-1 mt-2 lg:mt-0" @click="exportData()">
                  {{ $t("export") }}
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'view' && (props.row.log_type == 'push_button' || props.row.log_type == 'call_b' || props.row.log_type == 'man_down')">
                <button class="btn-blue-outline" @click.prevent="focusPushButton(props.row)">{{ $t("view") }}</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5 text-sm">
              {{ $t("secure_tool.no_logs_found") }}
            </div>
          </vue-good-table>
        </div>
        <div v-show="currentPageTab == 1" class="unit-gps-wrapper">
          <div class="w-full lg:w-5/6 map-container">
            <GmapMap :center="center" :zoom="zoom" map-type-id="roadmap" class="google_map_wrapper" ref="map" :options="{ fullscreenControl: mapFullScreenControl }">
              <GmapCluster :zoom-on-click="true">
                <GmapMarker v-for="(m, index) in markers_filtered" :position="m.position" :clickable="true" :draggable="false" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
              </GmapCluster>
              <GmapCluster :zoom-on-click="true" :styles="[{ url: require('@/assets/icon/warning.png'), width: 40, height: 34, textSize: 18, anchorText: [4, 0], textColor: '#fff' }]">
                <GmapMarker v-for="(m, i) in push_button_markers_filtered" :key="i" :position="m.position" :clickable="true" :draggable="false" :icon="{ url: require('@/assets/icon/map-warning.svg') }" @mouseover="showInfoWindow($event, m.infoText, true)" @mouseout="showInfoWindow($event, m.infoText, false)"></GmapMarker>
              </GmapCluster>
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"> </GmapInfoWindow>
              <GmapPolyline v-for="(p, index) in paths_filtered" :key="index" :options="p.options" :path="p.path" @mouseover="showInfoWindowPath($event, p.infoText, true, index)" @mouseout="showInfoWindowPath($event, p.infoText, false, index)"></GmapPolyline>
              <GmapPolygon v-for="(polygon, i) in polygonPath_filtered" :key="'geofence-' + i" :paths="polygon.path" :options="polygonOptions" ref="polygon" @mouseover="showInfoWindow($event, polygon.infoText, true)" @mouseout="showInfoWindow($event, polygon.infoText, false)" />
            </GmapMap>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
            <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/6 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30" v-if="mobileMenuActive">
              <div class="scroll-container-alarm border border-secondary-300">
                <div class="py-3 text-sm text-center text-secondary-600" v-if="alarms_filtered && alarms_filtered.length == 0">
                  <div>Inga larm hittade</div>
                </div>
                <div v-for="a in alarms_filtered" :key="a._id" class="alarm-item bg-white border-b border-secondary-300">
                  <div class="hover:bg-primary-50 cursor-pointer py-4 px-4" @click="focusAlarm(a.imei_number)">
                    <div class="text-sm text-primary-500 font-bold mb-1">
                      <span v-if="new RegExp('^SRT').test(a.type)" class="last-seen device-active-status" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TWIG').test(a.type)" class="last-seen device-active-status" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                      <span v-if="new RegExp('^EMERIT').test(a.type)" class="last-seen device-active-status" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      <span v-if="new RegExp('^TELTONIKA').test(a.type)" class="last-seen device-active-status" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                      {{ a.unit_name }}
                    </div>
                    <div v-if="customers.length > 1" class="text-xxs text-secondary-600 text-capitalize mb-1">{{ getCustomerNameFromUnitId(a.parent_id) }}</div>
                    <div class="text-xxs text-secondary-600 text-capitalize mb-1">{{ a.alarm_unit_name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div v-show="currentPageTab == 2">
          <vue-good-table
            ref="securposLogTable"
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="securposColumns"
            :rows="securposRowsFiltered"
            :search-options="{
              enabled: true,
              placeholder: 'Sök',
            }"
            :pagination-options="{
              enabled: true,
              perPage: 20,
              perPageDropdown: [20, 50, 100],
              dropdownAllowAll: false,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'view'">
                <button class="btn-blue-outline" @click.prevent="securposVisit(props.row)">{{ $t("secure_tool_table.view") }}</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5 text-sm">
              Inga loggar hittades
            </div>
          </vue-good-table>
        </div>
        <div v-show="currentPageTab == 3" class="unit-gps-wrapper-trip unit-gps-wrapper">
          <div v-if="this.showTripMap" class="w-full map-container" style="background-color: black;">
            <GmapMap :center="center" :zoom="tripZoom" map-type-id="roadmap" class="google_map_wrapper" ref="tripMap" :options="{ fullscreenControl: mapFullScreenControl }">
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"> </GmapInfoWindow>
              <GmapPolyline v-for="(p, index) in paths_filtered_trip" :key="index" :options="p.options" :path="p.path" @mouseover="showInfoWindowPath($event, p.infoText, true, index)" @mouseout="showInfoWindowPath($event, p.infoText, false, index)"></GmapPolyline>
            </GmapMap>
          </div>
          <vue-good-table
            styleClass="vgt-table"
            row-style-class="text-sm"
            :columns="tripReportColumns"
            :rows="tripReportRowsFiltered"
            :search-options="{ enabled: false, placeholder: 'Sök' }"
            :pagination-options="{
              enabled: true,
              perPage: 100,
              perPageDropdown: [100, 200, 500],
              dropdownAllowAll: false,
            }"
          >
            <div slot="table-actions">
              <div class="flex flex-wrap">
                <button class="btn-blue-outline mx-1 mt-2 lg:mt-0" @click="exportTrip()">
                  {{ $t("export") }}
                  <BaseIcon icon="file-excel" class="ml-1" />
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'mapAction'">
                <button class="btn-blue-outline" @click.prevent="loadTripMap(props.row)">{{ $t("secure_tool_table.view") }}</button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5 text-sm">
              Inga loggar hittades
            </div>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- email notifcation -->
    <modal name="email-notification" class="modal-inner modal-email-notification" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
      <span class="close-button" @click="hide('email-notification')"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header">
        {{ $t("secure_tool.notice") }} <span v-if="email_setting">- {{ email_setting.name }}</span>
      </div>
      <div class="modal-body" v-if="email_setting">
        <ValidationObserver ref="formEmailNotification">
          <div v-if="type == 'customer'" class="flex flex-wrap">
            <div v-if="customers.length > 1" class="w-full md:w-1/2 px-2">
              <select class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" v-model="selected_customer" @change="emailSettingChangeCustomer()">
                <option v-for="c in customers" :key="c._id" :value="c._id">{{ c.name }}</option>
              </select>
            </div>
            <div class="w-full md:w-1/2 px-2">
              <select class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-2 pt-1 pb-1" v-model="selected_unit_email" @change="emailSettingChangeUnit()">
                <option v-if="user.role != 'user'" value="">{{ $t("secure_tool.all_activities") }}</option>
                <option v-for="u in notificationUnits" :key="u.unit_id" :value="u.unit_id">{{ u.name }}</option>
              </select>
            </div>
          </div>
          <div v-if="type == 'customer'" class="mx-2 border-b border-secondary-400 my-5"></div>

          <div class="flex flex-wrap">
            <div class="w-full px-2">
              <BaseInput v-model="email_setting.notification_email" type="text" field_name="Email" placeholder="john@example.com, alice@example.com" />
              <div class="text-xs italic font-normal">{{ $t("secure_track.enter_multiple") }}</div>
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>
          <div class="flex flex-col my-5">
            <div class="w-full px-2 text-gray-600 text-sm font-bold pb-2 font-serif">
              <div class="clear-both">
                <div class="float-left">SMS</div>
                <div class="float-right">
                  <button class="btn-secondary-outline text-xxs py-1" @click.prevent="toggleAllWeek()" v-if="email_setting && email_setting.notification_phone.length > 0">
                    <span v-if="!weekExpanded">
                      {{ $t("expand") }}
                      <BaseIcon icon="plus-circle" class="ml-1" />
                    </span>
                    <span v-else>
                      {{ $t("minimize") }}
                      <BaseIcon icon="minus-circle" class="ml-1" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col px-2 mt-2 bg-gray-50 py-2" v-for="(n, i) in email_setting.notification_phone" :key="i">
              <div class="flex flex-wrap">
                <div class="w-5/12 pr-4 flex">
                  <span @click.prevent="toggleWeekVisibility(n)" class="pt-1 pr-3">
                    <span v-if="n.schedule != 'custom'" class="pr-3"></span>
                    <BaseIcon v-if="n.schedule == 'custom' && !n.isVisible" icon="chevron-down" class="text-black cursor-pointer" />
                    <BaseIcon v-if="n.schedule == 'custom' && n.isVisible" icon="chevron-up" class="text-black cursor-pointer" />
                  </span>
                  <ValidationProvider :name="'SMS Nummer ' + (i + 1)" rules="required|numeric|max:15" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="text" v-model="n.msisdn" :placeholder="$t('secure_tool_notice.phone_with_country')" class="w-full bg-white rounded text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-4/12 pr-4">
                  <ValidationProvider name="" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <select v-model="n.schedule" @change="smsScheduleChange(n)" class="bg-white rounded w-full text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1 hover:border-gray-400">
                        <option :value="o.id" v-for="o in sms_schedule_option" :key="o.id">{{ o.name }}</option>
                      </select>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-3/12 pr-4">
                  <div class="clear-both">
                    <button class="float-right text-red-500 py-1" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
                  </div>
                </div>
              </div>
              <div v-if="n.schedule == 'custom' && n.isVisible" class="flex flex-col mt-1 pl-8">
                <div v-for="(v, k) in n.weekly_time" :key="k" class="flex flex-wrap my-1">
                  <div class="w-2/12 text-xs font-semibold my-1">{{ getWeekName(k) }}</div>
                  <div class="w-2/12">
                    <select v-model="v.start" @change="checkStartEmpty(v)" class="bg-white rounded w-full text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1 hover:border-gray-400">
                      <template v-for="t in smsScheduleTime">
                        <option v-if="t == ''" value="" v-bind:key="t">Ingen</option>
                        <option v-else-if="t != '23:59'" :value="t" v-bind:key="t">{{ t }}</option>
                      </template>
                    </select>
                  </div>
                  <div v-show="v.start != ''" class="w-1/12 text-xxs text-center my-1">till</div>
                  <div v-show="v.start != ''" class="w-2/12">
                    <select v-model="v.end" class="bg-white rounded w-full text-sm text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-3 py-1 hover:border-gray-400">
                      <template v-for="(t, j) in smsScheduleTime">
                        <option v-if="j > findSMSIndexStart(v)" :value="t" v-bind:key="t">{{ t }}</option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full px-2">
              <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">{{ $t("secure_track.add") }} <BaseIcon icon="plus" class="ml-1" /></button>
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>
          <div class="flex flex-wrap">
            <div class="w-full px-2 text-lg font-bold mb-5">
              {{ $t("secure_tool.alarm_events") }}
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.push_button" :field_name="$t('alarm_events.sharp_alarm')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.callb" :field_name="$t('alarm_events.internal_alarm')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.man_down" :field_name="$t('alarm_events.man_down')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.low_battery" :field_name="$t('alarm_events.low_battery')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.alarm_offline" :field_name="$t('alarm_events.alarm_offline')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.rft_enter" :field_name="$t('alarm_events.radio_beacon')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.test_alarm" :field_name="$t('alarm_events.test_alarm_performed')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.timer_alarm" :field_name="$t('alarm_events.time_alarm')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.device_start" :field_name="$t('alarm_events.alarm_on')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.charger_on_off" :field_name="$t('alarm_events.loading_on')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.temperature_alarm" :field_name="$t('alarm_events.temperature_alarm')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.inp0_alarm" :field_name="$t('alarm_events.in_operation')" />
            </div>
            <div class="w-full md:w-1/4 px-2 my-2">
              <BaseSwitch v-model="email_setting.inp1_alarm" :field_name="$t('alarm_events.total_alarm')" />
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button class="btn-red-outline px-3 py-2 float-left" @click="hide('email-notification')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
          <button class="btn-green px-3 py-2 float-right" @click="updateCustomer()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 700;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "RoundingSurveillance",
  title() {
    let page = this.$t("page_titles.customer2");
    if (this.user.role == "user") page = this.$t("page_titles.department");
    return page;
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      mobileMenuActive: false,
      mapFullScreenControl: true,
      isLoading: false,
      type: this.$route.params.type,
      id: this.$route.params.id,
      currentPageTab: 0,
      zoom: this.$store.state.mapZoom,
      tripZoom: 9,
      center: this.$store.state.mapCenter,
      table_tab: 1,
      map_filter: 1,
      customer: null,
      unit: null,
      customers: [],
      units: [],
      alarms: [],
      alarms_filtered: null,
      dropdown_alarms: [],
      selected_unit: "",
      selected_alarm: "",
      date_range: [],
      gps: [],
      gps_log: [],
      track_alarm: [],
      rows: [],
      rows_unit: [],
      rows_filtered: [],

      tripPaths: [],
      tripReportRows: [],
      tripReportRowsFiltered: [],
      showTripMap: false,
      paths_filtered_trip: [],
      // todo: securpos filter by unit, alarm
      securposRows: [],
      securposRowsUnit: [],
      securposRowsFiltered: [],
      markers: [],
      markers_filtered: [],
      paths: [],
      paths_filtered: [],
      paths_filtered_unit: [],
      push_button_markers: [],
      push_button_markers_filtered: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      email_setting: null,
      smsScheduleTime: ["", "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59"],
      polygonOptions: {
        strokeColor: "#1A8DE9",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#1A8DE9",
        fillOpacity: 0.4,
        editable: false,
        draggable: false,
      },
      polygonPath: [],
      polygonPath_filtered: [],
      // email multiple customer, unit
      weekExpanded: false,
      notificationUnits: [],
      selected_customer: "",
      selected_unit_email: "",
      date_picker_lang: this.$store.state.appLocale,
      date_picker_langKey: 0,
    };
  },

  methods: {
    loadCurrentTabData() {
      // on initial page load data of tab 0 will be loaded and also customer, units, alarms objects will be populated
      if (this.currentPageTab == 0) this.getGPSLogs();
      // else if(this.currentPageTab == 1) this.getTripLogs();
      else if (this.currentPageTab == 2) this.getSecurposVisits();
      else if (this.currentPageTab == 3) this.getTripLogs();
    },

    async getGPSLogs() {
      try {
        this.isLoading = true;

        let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let api_url = `${process.env.VUE_APP_SERVER_URL}/gps-log/${this.type}/${this.id}/${start_date}/${end_date}`;

        let response = await this.axios.get(api_url);
        this.gps_log = response.data.logs;
        this.gps = response.data.gps;
        this.customers = response.data.customers;

        if (this.type == "customer") {
          this.units = response.data.units;

          if (this.customers.length == 1) this.setPageTitle(`${this.customers[0].name} - SecurTool`, "rounding_surveillance");
          else this.setPageTitle(`SecurTool`, "rounding_surveillance");

          let allPolygons = [];
          // unit geofence
          for (let i = 0; i < this.units.length; i++) {
            let unit = this.units[i];
            if (unit.geofence_polygon) {
              let p = unit.geofence_polygon.map((obj) => ({
                ...obj,
                unit_number: unit.unit_id,
                infoText: `${this.$t("secureTool.geofence_name")}: ${obj.name}<br>
                          ${this.$t("secureTool.department")}: ${unit.name}`,
              }));
              allPolygons.push(p);
            }
          }
          // customer geofence
          for (let i = 0; i < this.customers.length; i++) {
            let customer = this.customers[i];
            if (customer.geofence_polygon.length > 0) {
              let p = customer.geofence_polygon.map((obj) => ({
                ...obj,
                unit_number: "",
                infoText: `${this.$t("secureTool.geofence_name")}: ${obj.name}<br>
                          ${this.$t("secureTool.department")}: Alla verksamheter<br>
                          ${this.$t("secureTool.customer")}: ${customer.name}`,
              }));
              allPolygons.push(p);
            }
          }

          allPolygons = _.flatten(allPolygons);
          this.polygonPath = allPolygons;
          this.polygonPath_filtered = this.polygonPath;
        } else if (this.type == "unit") {
          this.unit = response.data.unit;
          this.units = [response.data.unit];
          this.setPageTitle(`${this.unit.name} - SecurTool`, "rounding_surveillance");
          let p = this.unit.geofence_polygon.map((obj) => ({
            ...obj,
            unit_number: this.unit.unit_id,
            infoText: `${this.$t("secureTool.geofence_name")} : ${obj.name}<br>
                      ${this.$t("secureTool.department")}: ${this.unit.name}`,
          }));
          this.polygonPath = p;
          this.polygonPath_filtered = this.polygonPath;
        }

        this.rows = [];
        this.rows_unit = [];
        this.rows_filtered = [];
        this.markers = [];
        this.markers_filtered = [];
        this.alarms = [];
        this.alarms_filtered = [];
        this.push_button_markers = [];
        this.push_button_markers_filtered = [];

        // alarms array
        let larms = [];

        response.data.alarms.forEach((element) => {
          let i = _.find(this.gps, { imei_number: element.imei_number });
          if (i) {
            let lastSeenObj = this.getAlarmLastSeenTime(i.updatedAt);
            element.lastSeen = lastSeenObj.lastSeen;
            element.lastSeenTime = lastSeenObj.lastSeenTime;
            if (this.type == "customer") element.alarm_unit_name = this.getUnitName(element.parent_id);
            else if (this.type == "unit") element.alarm_unit_name = this.unit.name;

            larms.push(element);

            if (i.gps_pos.lat != null) {
              // Standard icon
              let icon = require("@/assets/icon/new_small_map_marker.png");
              // Car icon
              if (new RegExp(/^TELTONIKA/g).test(element.type)) icon = require("@/assets/icon/car.png");
              // Icon depending on device type
              if (element.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
              if (element.type == "SRT341") icon = require("@/assets/icon/srt341.png");
              if (element.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
              if (element.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");

              // Twig devices icons
              if (/^TWIG/.test(element.type)) {
                icon = require("@/assets/icon/twig_map_marker.png");

                if (element.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                else if (/^TWIG One/.test(element.type)) icon = require("@/assets/icon/twig_one.png");
              }

              this.markers.push({
                icon: icon,
                position: { lat: i.gps_pos.lat, lng: i.gps_pos.lng },
                infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${element.unit_name}</b><br>
                          ${this.$t("map_info_popup.department")}: <b>${element.alarm_unit_name}</b>
                          `,
                unit_number: element.parent_id,
                imei_number: element.imei_number,
              });
            }
          }
        });
        this.alarms = larms;
        this.dropdown_alarms = larms;
        this.markers_filtered = this.markers;

        // gps logs
        this.gps_log.forEach((el) => {
          // table
          if (el.log_type != "location_update") this.rows.push(el);

          if (el.log_type == "push_button" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "push_button",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Push Button</b><br>
                        ${this.$t("map_info_popup.alarm_name")}: <b>${el.alarm_name}</b><br>
                        ${this.$t("map_info_popup.business_name")}: <b>${el.unit_name}</b><br>
                        ${this.$t("map_info_popup.timestamp")}: <b>${this.formatDate(el.createdAt)}</b><br>
                        ${this.$t("map_info_popup.lat")}: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }

          if (el.log_type == "call_b" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "call_b",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Internlarm</b><br>
                        ${this.$t("map_info_popup.alarm_name")}: <b>${el.alarm_name}</b><br>
                        ${this.$t("map_info_popup.business_name")}: <b>${el.unit_name}</b><br>
                        ${this.$t("map_info_popup.timestamp")}: <b>${this.formatDate(el.createdAt)}</b><br>
                        ${this.$t("map_info_popup.lat")}: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }

          if (el.log_type == "man_down" && el.gps_pos.lat != null) {
            this.push_button_markers.push({
              type: "man_down",
              position: el.gps_pos,
              unit_number: el.unit_number,
              customer_id: el.customer_id,
              imei_number: el.imei_number,
              timestamp: el.createdAt,
              infoText: `<b>Man down</b><br>
                        ${this.$t("map_info_popup.alarm_name")}: <b>${el.alarm_name}</b><br>
                        ${this.$t("map_info_popup.business_name")}: <b>${el.unit_name}</b><br>
                        ${this.$t("map_info_popup.timestamp")}: <b>${this.formatDate(el.createdAt)}</b><br>
                       ${this.$t("map_info_popup.lat")} Lat: <b>${el.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${el.gps_pos.lng.toFixed(3)}</b>
                        `,
            });
          }
        });

        this.rows = this.rows.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.rows_filtered = this.rows;
        this.rows_unit = this.rows;
        this.alarms_filtered = this.alarms;
        this.push_button_markers_filtered = this.push_button_markers;

        this.isLoading = false;

        // apply existing filter
        this.selectChangeFilterAlarm();
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    async getTripLogs() {
      try {
        this.isLoading = true;

        let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let api_url = `${process.env.VUE_APP_SERVER_URL}/trip-log/${this.type}/${this.id}/${start_date}/${end_date}`;

        let response = await this.axios.get(api_url);
        // this.gps = response.data.gps;
        this.track_alarm = response.data.track_alarm;

        this.paths = [];
        this.paths_filtered_unit = [];
        this.paths_filtered = [];
        this.tripReportRows = [];
        this.tripReportRowsFiltered = [];

        // push button & track user path
        this.track_alarm.forEach((track_path) => {
          let path = _.map(track_path.gps_path, "position");
          let tracking_type = track_path.tracking_type == "push_button" ? "Push-Button Spårning" : `Följ larm - ${track_path.user_name}`;
          let alarm = _.find(this.alarms, { imei_number: track_path.imei_number });

          if ((alarm && track_path.user_role != "admin" && track_path.user_role != "alarm_center") || (alarm && this.user.role == "admin")) {
            let unit_name = this.getUnitName(alarm.parent_id);
            let path_color = track_path.tracking_type == "push_button" ? "red" : this.getRandomPathColor();

            this.paths.push({
              path: path,
              unit_number: alarm.parent_id,
              imei_number: alarm.imei_number,
              type: track_path.tracking_type == "push_button" ? "pushb_tracking" : "user_tracking",
              options: {
                strokeColor: path_color,
                strokeOpacity: track_path.tracking_type == "push_button" ? 0.6 : 0.3,
                strokeWeight: 3,
                icons: [],
              },
              infoText: `<b>${tracking_type}</b><br>
                        ${this.$t("map_info_popup.alarm_name")}: <b>${alarm.unit_name}</b><br>
                        ${this.$t("map_info_popup.business_name")}: <b>${unit_name}</b><br>
                        ${this.$t("map_info_popup.timestamp")}: <b>${this.formatDate(track_path.createdAt)}</b>
                        `,
            });
          }
        });

        // daily unprocessed data
        response.data.dailyTrips.forEach((element) => {
          let device = _.find(this.alarms, { imei_number: element.imei_number });

          if (device) {
            let unit_name = this.getUnitName(device.parent_id);

            let trip = {
              name: device.unit_name,
              startTime: element.startTime,
              endTime: element.endTime,
              startPosition: element.startPosition,
              endPosition: element.endPosition,
              startAddress: element.startAddress || "",
              endAddress: element.endAddress || "",
              imei_number: element.imei_number,
              totalDuration: this.moment.duration(element.duration, "seconds").humanize(),
              totalDistance: element.distance,
              expenses: "",
              unit_id: element.unit_id,
            };

            this.tripReportRows.push(trip);

            this.paths.push({
              path: element.path,
              unit_number: element.unit_id,
              imei_number: element.imei_number,
              type: "daily_tracking",
              startTime: element.startTime,
              endTime: element.endTime,
              options: {
                strokeColor: this.getRandomPathColor(),
                strokeOpacity: 0.8,
                strokeWeight: 3.4,
                icons: [],
              },
              infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${device.unit_name}</b><br>
                        ${this.$t("map_info_popup.department")}: <b>${unit_name}</b><br>
                        ${this.$t("map_info_popup.start_time")}: <b>${this.formatDate(element.startTime)}</b><br>
                        ${this.$t("map_info_popup.end_time")}: <b>${this.formatDate(element.endTime)}</b><br>
                        ${this.$t("map_info_popup.distance_covered")}: <b>${(element.distance / 1000).toFixed(2)} km</b>`,
            });
          }
        });

        this.reverseGeocodeAddresses();

        // history trips
        response.data.historyTrips.forEach((element) => {
          let alarm = _.find(this.alarms, { imei_number: element.imei_number });

          if (alarm) {
            let unit_name = this.getUnitName(alarm.parent_id);

            let trip = {
              name: alarm.unit_name,
              startTime: element.startTime,
              endTime: element.endTime,
              startAddress: element.startAddress || "",
              endAddress: element.endAddress || "",
              totalDuration: this.moment.duration(element.time_driving, "minutes").humanize(),
              totalDistance: element.distance_travelled,
              expenses: "",
              unit_id: element.unit_id,
            };

            this.tripReportRows.push(trip);

            this.paths.push({
              path: element.path,
              unit_number: element.unit_id,
              imei_number: element.imei_number,
              type: "daily_tracking",
              startTime: element.startTime,
              endTime: element.endTime,
              options: {
                strokeColor: this.getRandomPathColor(),
                strokeOpacity: 0.8,
                strokeWeight: 3.4,
                icons: [],
              },
              infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${alarm.unit_name}</b><br>
                        ${this.$t("map_info_popup.department")}: <b>${unit_name}</b><br>
                        ${this.$t("map_info_popup.start_time")}: <b>${this.formatDate(element.startTime)}</b><br>
                        ${this.$t("map_info_popup.end_time")}: <b>${this.formatDate(element.endTime)}</b><br>
                        ${this.$t("map_info_popup.distance_covered")}: <b>${Math.ceil(element.distance_travelled / 1000)} km</b>`,
            });
          }
        });

        // this.$refs.map.$mapPromise.then(() => {
        //   this.paths_filtered = this.paths;
        // });
        this.paths_filtered = this.paths;
        this.tripPaths = this.paths_filtered;
        // sort date latest to old
        this.tripReportRows = this.tripReportRows.sort(function(a, b) {
          if (a.startTime < b.startTime) return 1;
          else if (a.startTime > b.startTime) return -1;
          return 0;
        });
        this.tripReportRowsFiltered = this.tripReportRows;
        this.isLoading = false;

        // apply existing filter
        this.selectChangeFilterAlarm();
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    // securpos start
    async getSecurposVisits() {
      try {
        let start = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
        let end = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/securpos/visits/${this.type}/${this.id}/${start}/${end}`);

        // reset table
        this.securposRows = [];
        this.securposRowsUnit = [];
        this.securposRowsFiltered = [];

        this.securposRows = response.data.visits;
        this.securposRowsUnit = this.securposRows;
        this.securposRowsFiltered = this.securposRows;
      } catch (error) {
        this.handleError(error);
      }
    },

    filterPathForTrip(startTime, endTime) {
      this.paths_filtered_trip = this.paths.filter((pathEl) => {
        return pathEl.startTime === startTime && pathEl.endTime === endTime;
      });
      const vm = this;
      vm.$nextTick(() => {
        const centerIndex = Math.round(this.paths_filtered_trip[0].path.length / 2);
        vm.$refs.tripMap.panTo({ lat: this.paths_filtered_trip[0].path[centerIndex].lat, lng: this.paths_filtered_trip[0].path[centerIndex].lng });
        vm.$refs.tripMap.$mapObject.setZoom(12);
      });
    },

    getUnitNameFromUnitId(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u.name;
      else return id;
    },

    getAlarmNameFromPhone(phone) {
      let a = _.find(this.alarms, { phone: phone });
      if (a) return a.unit_name;
      else return phone;
    },

    formatVisitAddr(address) {
      if (address) return `${address.area} ${address.floor} ${address.building} ${address.visitor}`;
      else return "";
    },

    formatVisitDuration(timeLength) {
      const timeOptions = [
        { name: "15 minuter", value: 900 },
        { name: "30 minuter", value: 1800 },
        { name: "1 timme", value: 3600 },
        { name: "2 timmar", value: 7200 },
      ];

      let t = _.find(timeOptions, { value: timeLength });

      if (t) return t.name;
      else return timeLength;
    },

    // securpos end

    async reverseGeocodeAddresses() {
      var vm = this;

      vm.$refs.map.$mapPromise.then(async () => {
        for (let i = 0; i < vm.tripReportRows.length; i++) {
          let t = vm.tripReportRows[i];
          if (t.startAddress == "") {
            let startAddress = await vm.reverseGeocode(t.startPosition);
            t.startAddress = startAddress;
          }

          if (t.endAddress == "" && t.endTime) {
            let endAddress = await vm.reverseGeocode(t.endPosition);
            t.endAddress = endAddress;
          }
        }
      });
    },

    async reverseGeocode(point) {
      var vm = this;
      let address = "";

      if (!point) return address;

      try {
        const geocoder = new vm.google.maps.Geocoder();
        let res = await geocoder.geocode({ location: point });
        address = res.results[0].formatted_address;
      } catch (error) {
        console.log(error);
      }

      return address;
    },

    getRandomPathColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    focusAlarm(imei) {
      var vm = this;

      let current_pos = _.find(this.gps, { imei_number: imei });

      if (current_pos && current_pos.gps_pos.lat != null) {
        vm.$refs.map.$mapObject.panTo({ lat: current_pos.gps_pos.lat, lng: current_pos.gps_pos.lng });
        vm.$refs.map.$mapObject.setZoom(16);
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    showInfoWindowPath(event, infoText, show, index) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;

        this.paths_filtered[index].options.icons = [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2 }, offset: "100%", repeat: "200px" }];
        this.paths_filtered[index].options.strokeWeight = 4;

        for (let i = 0; i < this.paths_filtered.length; i++) {
          let p = this.paths_filtered[i];
          if (i != index) p.options.strokeOpacity = 0.2;
        }
      } else {
        this.infoWinOpen = show;
        this.paths_filtered[index].options.icons = [];
        this.paths_filtered[index].options.strokeWeight = 3.4;

        for (let i = 0; i < this.paths_filtered.length; i++) {
          let p = this.paths_filtered[i];
          p.options.strokeOpacity = 1;
        }
      }
    },

    changeCurrentPage(value) {
      this.currentPageTab = value;
      if (this.currentPageTab == 1) this.generateBounds();
      this.showTripMap = false;
      this.paths_filtered_trip = [];

      this.loadCurrentTabData();
    },

    changeTableFilter(value) {
      this.table_tab = value;

      if (this.table_tab == 1) this.rows_filtered = this.rows_unit;
      else if (this.table_tab == 2) this.rows_filtered = this.getFilterData("push_button");
      else if (this.table_tab == 3) this.rows_filtered = this.getFilterData("call_b");
      else if (this.table_tab == 4) this.rows_filtered = this.getFilterData("man_down");
      else if (this.table_tab == 5) this.rows_filtered = this.getFilterData("low_battery");
      else if (this.table_tab == 6) this.rows_filtered = this.getFilterData("rft_");
      else if (this.table_tab == 7) this.rows_filtered = this.getFilterData("test_alarm_confirm");
      else if (this.table_tab == 8) this.rows_filtered = this.getFilterData("timer_alarm_stop");
      else if (this.table_tab == 9) this.rows_filtered = this.getFilterData("device_");
      else if (this.table_tab == 10) this.rows_filtered = this.getFilterData("charge_");
      else if (this.table_tab == 11) this.rows_filtered = this.getFilterData("alarm_");
      else if (this.table_tab == 12) this.rows_filtered = this.getFilterData("geofence");
    },

    getFilterData(type) {
      let log_type = type;

      if (log_type == "rft_") {
        let r = _.filter(this.rows_unit, function(o) {
          return new RegExp(/^rft_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "device_") {
        let r = _.filter(this.rows_unit, function(o) {
          return new RegExp(/^device_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "charge_") {
        let r = _.filter(this.rows_unit, function(o) {
          return new RegExp(/^charge_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "alarm_") {
        let r = _.filter(this.rows_unit, function(o) {
          return new RegExp(/^alarm_/).test(o.log_type);
        });
        return r;
      } else if (log_type == "geofence") {
        let r = _.filter(this.rows_unit, function(o) {
          return new RegExp(/^geofence/).test(o.log_type);
        });
        return r;
      } else {
        let r = _.filter(this.rows_unit, { log_type: log_type });
        return r;
      }
    },

    filterTableByUnit() {
      if (this.selected_alarm != "") {
        this.rows_unit = _.filter(this.rows, { imei_number: this.selected_alarm });
      } else if (this.selected_unit != "") {
        this.rows_unit = _.filter(this.rows, { unit_number: this.selected_unit });
      } else {
        this.rows_unit = this.rows;
      }
    },

    filterMapByUnit() {
      if (this.selected_alarm != "") {
        this.paths_filtered_unit = _.filter(this.paths, { imei_number: this.selected_alarm });
        this.paths_filtered = this.paths_filtered_unit;

        this.markers_filtered = _.filter(this.markers, { imei_number: this.selected_alarm });
        this.push_button_markers_filtered = _.filter(this.push_button_markers, { imei_number: this.selected_alarm });
      } else if (this.selected_unit != "") {
        this.paths_filtered_unit = _.filter(this.paths, { unit_number: this.selected_unit });
        this.paths_filtered = this.paths_filtered_unit;

        this.markers_filtered = _.filter(this.markers, { unit_number: this.selected_unit });
        this.push_button_markers_filtered = _.filter(this.push_button_markers, { unit_number: this.selected_unit });
        this.polygonPath_filtered = _.filter(this.polygonPath, { unit_number: this.selected_unit });
      } else {
        this.paths_filtered_unit = this.paths;
        this.paths_filtered = this.paths_filtered_unit;

        this.markers_filtered = this.markers;
        this.push_button_markers_filtered = this.push_button_markers;
        this.polygonPath_filtered = this.polygonPath;
      }
    },

    filterAlarmsByUnit() {
      if (this.selected_alarm != "") {
        this.alarms_filtered = _.filter(this.alarms, { imei_number: this.selected_alarm });
        // fix, dropwdown alarm listing all when loading new data
        if (this.selected_unit != "") this.dropdown_alarms = _.filter(this.alarms, { parent_id: this.selected_unit });
      } else if (this.selected_unit != "") {
        this.alarms_filtered = _.filter(this.alarms, { parent_id: this.selected_unit });
        this.dropdown_alarms = this.alarms_filtered;

        let a = _.find(this.dropdown_alarms, { imei_number: this.selected_alarm });
        if (!a) this.selected_alarm = "";
      } else {
        this.alarms_filtered = this.alarms;
        this.dropdown_alarms = this.alarms;
      }
    },

    changeMapFilter(value) {
      this.map_filter = value;

      this.filterMapByUnit();

      if (this.map_filter != 1 && this.map_filter != 2 && this.map_filter != 8 && this.map_filter != 9) this.push_button_markers_filtered = [];
      if (this.map_filter != 1 && this.map_filter != 6) this.markers_filtered = [];
      if (this.map_filter != 1 && this.map_filter != 7) this.polygonPath_filtered = [];

      if (this.map_filter != 1) this.paths_filtered = [];

      if (this.map_filter == 2) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "push_button" });
      if (this.map_filter == 8) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "call_b" });
      if (this.map_filter == 9) this.push_button_markers_filtered = _.filter(this.push_button_markers_filtered, { type: "man_down" });

      if (this.map_filter == 3) this.paths_filtered = _.filter(this.paths_filtered_unit, { type: "daily_tracking" });
      else if (this.map_filter == 4) this.paths_filtered = _.filter(this.paths_filtered_unit, { type: "pushb_tracking" });
      else if (this.map_filter == 5) this.paths_filtered = _.filter(this.paths_filtered_unit, { type: "user_tracking" });

      this.infoWinOpen = false;
    },

    filterTripReport() {
      if (this.selected_alarm == "") {
        this.tripReportRowsFiltered = this.tripReportRows;
      } else {
        this.tripReportRowsFiltered = _.filter(
          this.tripReportRows,
          function(row) {
            return row.imei_number == this.selected_alarm;
          }.bind(this)
        );
      }
    },

    selectChangeFilter() {
      this.selected_alarm = "";
      this.filterAlarmsByUnit();
      this.filterTableByUnit();
      this.changeTableFilter(this.table_tab);
      this.changeMapFilter(this.map_filter);
      this.generateBounds();
      this.infoWinOpen = false;
    },

    selectChangeFilterAlarm() {
      this.filterAlarmsByUnit();
      this.filterTableByUnit();
      this.changeTableFilter(this.table_tab);
      this.changeMapFilter(this.map_filter);
      this.generateBounds();
      this.infoWinOpen = false;

      if (this.currentPageTab === 3) {
        this.filterTripReport();
      }
    },

    focusPushButton(report) {
      this.currentPageTab = 1;

      if (report.log_type == "push_button") {
        this.map_filter = 2;
        this.changeMapFilter(this.map_filter);

        let pushb_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "push_button" });
        if (pushb_marker) this.showInfoWindow({ latLng: pushb_marker.position }, pushb_marker.infoText, true);
      }

      if (report.log_type == "call_b") {
        this.map_filter = 8;
        this.changeMapFilter(this.map_filter);

        let callb_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "call_b" });
        if (callb_marker) this.showInfoWindow({ latLng: callb_marker.position }, callb_marker.infoText, true);
      }

      if (report.log_type == "man_down") {
        this.map_filter = 9;
        this.changeMapFilter(this.map_filter);

        let mandown_marker = _.find(this.push_button_markers_filtered, { timestamp: report.createdAt, type: "man_down" });
        if (mandown_marker) this.showInfoWindow({ latLng: mandown_marker.position }, mandown_marker.infoText, true);
      }

      this.$refs.map.$mapObject.panTo({ lat: report.gps_pos.lat, lng: report.gps_pos.lng });
      this.$refs.map.$mapObject.setZoom(16);
    },

    loadTripMap(row) {
      this.showTripMap = true;
      this.$nextTick(() => {
        this.filterPathForTrip(row.startTime, row.endTime);
      });
    },

    exportData() {
      const newColumns = this.rows_filtered.map((item) => ({
        [this.$t("log_export.department")]: item.unit_name,
        [this.$t("log_export.alarm_name")]: item.alarm_name,
        [this.$t("log_export.timestamp")]: item.createdAt,
        [this.$t("log_export.report")]: item.log_report,
        [this.$t("log_export.latitude")]: item.gps_pos.lat,
        [this.$t("log_export.longitude")]: item.gps_pos.lng,
      }));

      let filtered = newColumns.filter((el) => Object.keys(el).length);

      this.excelExport(filtered, "log", "larm_logs");
    },
    exportTrip() {
      const newColumns = this.tripReportRowsFiltered.map((item) => {
        return {
          [this.$t("trip_export.department_name")]: this.getUnitNameFromUnitId(item.unit_id),
          [this.$t("trip_export.device_name")]: item.name,
          [this.$t("trip_export.start_time")]: this.formatDate(item.startTime),
          [this.$t("trip_export.end_time")]: this.formatDate(item.endTime),
          [this.$t("trip_export.start_address")]: item.startAddress,
          [this.$t("trip_export.final_address")]: item.endAddress,
          [this.$t("trip_export.total_duration")]: item.totalDuration,
          [this.$t("trip_export.total_distance")]: this.formatDistance(item.totalDistance),
          [this.$t("trip_export.expense")]: item.expenses,
        };
      });
      let filtered = newColumns.filter((el) => Object.keys(el).length);
      this.excelExport(filtered, "trips", "trip report");
    },

    getUnitName(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u.name;
      else return "";
    },

    getCustomerNameFromUnitId(unit_id) {
      let u = _.find(this.units, { unit_id: unit_id });
      if (u) {
        let c = _.find(this.customers, { _id: u.parent_id });
        if (c) return c.name;
        else return "";
      } else return "";
    },

    async updateCustomer() {
      this.$refs.formEmailNotification.validate().then(async (success) => {
        if (!success) {
          return;
        }

        let notification_phone = [];

        for (let i = 0; i < this.email_setting.notification_phone.length; i++) {
          let n = this.email_setting.notification_phone[i];
          delete n.isVisible;
          notification_phone.push(n);
        }

        let data = {
          notification_email: this.email_setting.notification_email,
          notification_phone: notification_phone,
          push_button: this.email_setting.push_button,
          callb: this.email_setting.callb,
          man_down: this.email_setting.man_down,
          low_battery: this.email_setting.low_battery,
          device_start: this.email_setting.device_start,
          charger_on_off: this.email_setting.charger_on_off,
          temperature_alarm: this.email_setting.temperature_alarm,
          inp0_alarm: this.email_setting.inp0_alarm,
          inp1_alarm: this.email_setting.inp1_alarm,
          rft_enter: this.email_setting.rft_enter,
          alarm_offline: this.email_setting.alarm_offline,
          test_alarm: this.email_setting.test_alarm,
          timer_alarm: this.email_setting.timer_alarm,
        };

        try {
          let api_url = `${process.env.VUE_APP_SERVER_URL}/customer/${this.selected_customer}`;
          if (this.selected_unit_email) {
            let u = _.find(this.units, { unit_id: this.selected_unit_email });
            if (u) api_url = `${process.env.VUE_APP_SERVER_URL}/units/${u._id}`;
          }
          if (this.type == "unit") api_url = `${process.env.VUE_APP_SERVER_URL}/units/${this.email_setting._id}`;

          let response = await this.axios.put(api_url, data);
          if (this.type == "customer") {
            if (this.type == "unit" || this.selected_unit_email) {
              let unit_index = _.findIndex(this.units, { _id: response.data.data._id });
              if (unit_index >= 0) this.units[unit_index] = response.data.data;
            } else {
              let customer_index = _.findIndex(this.customers, { _id: response.data.data._id });
              if (customer_index >= 0) this.customers[customer_index] = response.data.data;
            }
          } else if (this.type == "unit") this.unit = response.data.data;

          this.hide("email-notification");
        } catch (error) {
          this.handleError(error);
        }
      });
    },

    formatDate(date) {
      if (date)
        return this.moment(date)
          .tz("Europe/Stockholm")
          .format("ddd, YYYY-MM-DD, HH:mm");
      else "";
    },

    formatTime(time) {
      const m = Math.floor(time / 60);
      const s = Math.floor((time % 3600) % 60);
      const minutes = m < 9 ? "0" + m : m;
      return minutes + ":" + s;
    },

    formatDistance(meter) {
      if (meter) {
        return (meter / 1000).toFixed(2) + " Km";
      } else {
        return "";
      }
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      this.date_range = [start, end];
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    selectToday(emit) {
      const start = new Date(this.moment().startOf("day"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    selectCurrentMonth(emit) {
      const start = new Date(this.moment().startOf("month"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastMonth(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "month")
          .startOf("month")
      );
      const end = new Date(this.moment(start).endOf("month"));
      const date = [start, end];
      emit(date);
    },
    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
      });
    },

    show(id) {
      if (this.type == "customer") {
        if (this.user.role == "user") {
          // one customer, multiple unit
          this.selected_unit_email = this.selected_unit;
          if (this.selected_unit_email == "" && this.units.length > 0) {
            this.selected_customer = this.units[0].parent_id;
            this.selected_unit_email = this.units[0].unit_id;
          }

          this.notificationUnits = _.filter(this.units, { parent_id: this.selected_customer });
          this.emailSettingChangeUnit();
        } else {
          // multiple customer, multiple unit
          this.selected_customer = this.customers[0]._id;
          this.emailSettingChangeCustomer();
        }
      } else {
        this.email_setting = _.cloneDeep(this.unit);
        this.addWeekVisibility();
      }

      this.$modal.show(id);
    },

    emailSettingChangeCustomer() {
      let c = _.find(this.customers, { _id: this.selected_customer });
      if (c) {
        this.email_setting = _.cloneDeep(c);

        this.selected_unit_email = "";
        this.notificationUnits = _.filter(this.units, { parent_id: this.selected_customer });

        if (this.$refs["formEmailNotification"]) this.$refs["formEmailNotification"].reset();
      }
      this.addWeekVisibility();
    },

    emailSettingChangeUnit() {
      if (this.selected_unit_email == "") {
        let c = _.find(this.customers, { _id: this.selected_customer });
        if (c) this.email_setting = _.cloneDeep(c);

        if (this.$refs["formEmailNotification"]) this.$refs["formEmailNotification"].reset();
      } else {
        let u = _.find(this.units, { unit_id: this.selected_unit_email });
        if (u) this.email_setting = _.cloneDeep(u);

        if (this.$refs["formEmailNotification"]) this.$refs["formEmailNotification"].reset();
      }
      this.addWeekVisibility();
    },

    addWeekVisibility() {
      if (this.email_setting && this.email_setting.notification_phone.length > 0) {
        this.weekExpanded = false;

        let notification_phone = [];
        for (let i = 0; i < this.email_setting.notification_phone.length; i++) {
          let n = this.email_setting.notification_phone[i];
          notification_phone.push({ ...n, isVisible: false });
        }
        this.email_setting.notification_phone = notification_phone;
      }
    },

    toggleWeekVisibility(n) {
      n.isVisible = !n.isVisible;
    },

    toggleAllWeek() {
      this.weekExpanded = !this.weekExpanded;

      if (this.email_setting && this.email_setting.notification_phone.length > 0) {
        this.email_setting.notification_phone.forEach((n) => {
          if (this.weekExpanded) n.isVisible = true;
          else n.isVisible = false;
        });
      }
    },

    smsScheduleChange(n) {
      if (n.schedule == "custom") n.isVisible = true;
    },

    addSMSInput() {
      this.email_setting.notification_phone.push({
        msisdn: "",
        schedule: "allday",
        weekly_time: {
          mon: { start: "08:00", end: "17:00" },
          tue: { start: "08:00", end: "17:00" },
          wed: { start: "08:00", end: "17:00" },
          thu: { start: "08:00", end: "17:00" },
          fri: { start: "08:00", end: "17:00" },
          sat: { start: "08:00", end: "17:00" },
          sun: { start: "08:00", end: "17:00" },
        },
        isVisible: true,
      });
    },

    getWeekName(weekNumber) {
      let weeks = {
        mon: this.$t("days.mon"),
        tue: this.$t("days.tue"),
        wed: this.$t("days.wed"),
        thu: this.$t("days.thu"),
        fri: this.$t("days.fri"),
        sat: this.$t("days.sat"),
        sun: this.$t("days.sun"),
      };

      return weeks[weekNumber];
    },

    checkStartEmpty(time) {
      if (time.start == "") time.end = "";
    },
    findSMSIndexStart(time) {
      let i = _.findIndex(this.smsScheduleTime, function(v) {
        return v == time.start;
      });

      if (i) {
        let currentEndIndex = _.findIndex(this.smsScheduleTime, function(v) {
          return v == time.end;
        });

        if (currentEndIndex <= i && time.end != "23:59") time.end = this.smsScheduleTime[i + 1];

        return i;
      }
    },

    removeSMSInput(i) {
      this.email_setting.notification_phone.splice(i, 1);
    },

    hide(id) {
      this.selected_customer = "";
      this.email_setting = null;
      this.$modal.hide(id);
    },

    resetFilters() {
      this.$refs["securtoolLogTable"].reset();
      this.$refs["securtoolLogTable"].globalSearchTerm = "";
      this.selected_unit = "";
      this.table_tab = 1;
      this.map_filter = 1;
      this.selectChangeFilter();
    },

    generateBounds() {
      var vm = this;
      if (this.markers_filtered.length > 0) {
        setTimeout(() => {
          vm.$refs.map.$mapPromise.then((map) => {
            const bounds = new vm.google.maps.LatLngBounds();
            for (let m of this.markers_filtered) {
              bounds.extend(m.position);
            }

            var offset = 0.002;
            var center = bounds.getCenter();
            bounds.extend(new vm.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new vm.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

            map.fitBounds(bounds);
          });
        }, 500);
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    google: gmapApi,
    columns() {
      return [
        { label: this.$t("secure_tool_table.business_name"), field: "unit_name" },
        { label: this.$t("secure_tool_table.alerm_name"), field: "alarm_name" },
        { label: this.$t("secure_tool_table.timestamp"), field: "createdAt", type: "date", formatFn: this.formatDate },
        { label: this.$t("secure_tool_table.report_reason"), field: "log_report" },
        { label: "", field: "view", sortable: false },
      ];
    },
    securposColumns() {
      return [
        { label: this.$t("securpos_table.business_name"), field: "unit", formatFn: this.getUnitNameFromUnitId },
        { label: this.$t("securpos_table.alerm_name"), field: "phone", formatFn: this.getAlarmNameFromPhone },
        { label: this.$t("securpos_table.visiting_address"), field: "address", formatFn: this.formatVisitAddr },
        { label: this.$t("securpos_table.visiting_hours"), field: "startTime", formatFn: this.formatDate },
        { label: this.$t("securpos_table.length_of_stay"), field: "timeLength", formatFn: this.formatVisitDuration },
        { label: this.$t("securpos_table.timestamp"), field: "createdAt", type: "date", formatFn: this.formatDate },
      ];
    },
    tripReportColumns() {
      return [
        { label: this.$t("trip_report_table.department_name"), field: "unit_id", formatFn: this.getUnitNameFromUnitId },
        { label: this.$t("trip_report_table.device_name"), field: "name" },
        { label: this.$t("trip_report_table.start_time"), field: "startTime", type: "date", formatFn: this.formatDate },
        { label: this.$t("trip_report_table.end_time"), field: "endTime", type: "date", formatFn: this.formatDate },
        { label: this.$t("trip_report_table.total_duration"), field: "totalDuration" },
        { label: this.$t("trip_report_table.start_address"), field: "startAddress", sortable: false },
        { label: this.$t("trip_report_table.final_address"), field: "endAddress", sortable: false },
        { label: this.$t("trip_report_table.total_distance"), field: "totalDistance", formatFn: this.formatDistance },
        // { label: this.$t("trip_report_table.fuel_consumed"), field: "fuelConsumed" },
        { label: this.$t("trip_report_table.expense"), field: "expenses", sortable: false },
        { label: this.$t("trip_report_table.map"), field: "mapAction", sortable: false },
      ];
    },
    sms_schedule_option() {
      return [
        { name: this.$t("secure_tool_notice.all_day"), id: "allday" },
        { name: this.$t("secure_tool_notice.customized"), id: "custom" },
      ];
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    this.initDateRange();
    this.loadCurrentTabData();

    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.generateMobileMenu();
    this.checkMobile();

    this.$store.watch(
      (state) => state.appLocale,
      (newLocale) => {
        //console.log("🚀 ~ mounted ~ newLocale:", newLocale);

        this.date_picker_lang = newLocale;
        this.date_picker_langKey++;

        let page = this.$t("page_titles.customer2");
        if (this.user.role === "user") {
          page = this.$t("page_titles.department");
        }
        document.title = page;
      },
      { immediate: true }
    );

    console.log("🚀 ~ mounted ~ newLocale:", this.$store.state.appLocale);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
